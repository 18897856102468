import { render, staticRenderFns } from "./AnularReserva.vue?vue&type=template&id=10a7bb1e&scoped=true&"
import script from "./AnularReserva.vue?vue&type=script&lang=ts&"
export * from "./AnularReserva.vue?vue&type=script&lang=ts&"
import style0 from "./AnularReserva.vue?vue&type=style&index=0&id=10a7bb1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a7bb1e",
  null
  
)

export default component.exports